.pickList {
  h1 {
    margin: 0;
  }

  .picklist-dimensions {
    display: flex;
    font-size: 10px;
    color: #7194ac;

    div:first-child {
      margin-right: 5px;
    }
  }

  .picklist-section {
    padding: 0px;

    h4 {
      padding-top: 13px;
      padding-bottom: 7px;
    }
  }

  .btnLink:not(.config) {
    display: none !important;
  }

  .signatures {
    @include breakpoint($tablet) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
    }

    .signaturePad {
      margin-bottom: 20px;
    }
  }

  .flags {
    margin: 5px 0;

    span {
      padding: 0 14px 0 6px;
      background-color: $blue;
      display: inline-block;
      color: $white;
      text-align: center;
      margin-right: 8px;
      position: relative;

      &:after {
        content: " ";
        background: $white;
        position: absolute;
        top: 5px;
        right: -10px;
        width: 12px;
        height: 12px;
        transform-origin: center top;
        transform: rotate(41deg);
      }
    }
  }

  .picklist-container {
    padding: 0px;

    .picklistContentContainer {
      @media screen and (max-width: 768px) {
        padding: 0px;
      }

      h2 {
        font-size: 16px;
        padding-left: 15px;
        padding-top: 20px;
      }
    }

    .picklistToggles {
      display: none;

      @include breakpoint($tablet) {
        display: block;
      }
    }

    .filter {
      background-color: #c1cdd7;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 5px;

      .search {
        display: flex;
        align-items: center;
      }

      .mobile-actions-container {
        display: none;
      }

      @media screen and (max-width: 768px) {
        gap: 10px;
        padding: 10px 15px;
        flex-direction: column;

        .sortRow {
          @media screen and (max-width: 768px) {
            display: none;
          }
        }

        .mobile-actions-container {
          padding: 0;
          width: 100%;
          display: grid;
          grid-gap: 5px;
          margin-bottom: 0;
          box-sizing: border-box;
          background-color: #c1cdd7;
          grid-template-columns: 3fr 1fr;
        }

        .quickScan {
          margin-bottom: 0;
        }

        .mobile-actions-container .rTableHead {
          width: 100%;
        }

        .mobile-actions-container .rTableHead:nth-child(1) {
          grid-column: span 2;
        }

        .mobile-actions-container .rTableHead:nth-child(2) {
          grid-column: span 1;
        }

        .mobile-actions-container .rTableHead:nth-child(3) {
          grid-column: span 1;
        }

        .mobile-actions-container button,
        .mobile-actions-container select,
        .mobile-actions-container a {
          width: 100%;
          max-width: 100%;
          font-size: 16px;
          font-weight: 400;
          text-align: center;
          box-sizing: border-box;
        }

        .selectAction {
          padding-right: 0 !important;
        }
      }

      .filter-button {
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }
    }

    .filter-radio-group {
      display: flex;

      .radio {
        max-width: 20px;
      }

      div {
        div {
          align-items: center;

          label {
            margin-right: 10px !important;
            font-weight: bold;
            white-space: nowrap;
            font-size: 10px !important;
          }
          div {
            margin-right: 3px !important;
            margin-top: 0px !important;
            width: 20px !important;
            height: 20px !important;
          }
        }
      }
    }

    .items {
      clear: both;
      @include breakpoint($tablet) {
        display: flex;
        flex-direction: column;

        & > * {
          grid-row: 1;
        }
        section {
          margin-bottom: 0;
        }
        .addons {
          grid-row: 2;
        }
      }

      .grid {
        border-spacing: 0;

        .shortageTrigger {
          margin-right: 10px;
          cursor: pointer;

          svg {
            margin: 0 !important;
            fill: $orange;
          }
        }

        input[type='text'] {
          width: 45px;
          margin: 0 5px 0 0;
          background-color: $lightgrey;
        }
        button {
          height: 20px;
          width: 60px;
          padding: 0;
          border: none;
          background-color: $blue;
          @include border-radius(2px);
          font-size: 12px;
          font-weight: $bold;
          line-height: 20px;
          color: $white;
          text-transform: uppercase;

          &.edit {
            background-color: $lightgrey;
            color: $darkblue;
          }
        }

        .pickListHeaders {
          @media screen and (max-width: 768px) {
            display: none;
          }

          @include breakpoint($tablet) {
            display: table-header-group;
          }
        }

        .picklistItemRow {
          padding-top: 12px;
          padding-bottom: 12px;
        }

        .mobilePickListRow {
          width: 100vw;
          padding-left: 15px;
          padding-right: 15px;

          a {
            margin-top: 3px;
            font-size: 16px;
            font-weight: 400;
          }

          .image {
            width: 135px;
            height: 100%;

            div {
              width: 100%;
              height: 100%;

              figure {
                height: 100% !important;
              }
            }
          }

          &.notes {
            display: flex;
            align-items: center;
            font-size: 14px;
            padding-top: 12px;

            .label {
              margin-bottom: 0px;
              margin-right: 10px;
              font-size: 10px;
              padding-top: 0px;
            }
          }

          &.description {
            display: flex;
            align-items: center;
            height: 96px;

            @media screen and (max-width: 768px) {
              height: fit-content;
            }

            border-top: 1px solid $meddarkgrey;
            padding-top: 12px;
          }

          .pickListItemDescription {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
          }

          .update {
            display: flex;
            width: 100% !important;
            padding: 0px !important;
            height: 55px !important;

            .btn {
              margin-right: 0px;
              width: 100%;
            }
          }

          .rTableCell {
            border-top: none;
          }

          &.actionRow {
            display: flex;

            .rTableCell {
              display: flex;
              align-items: center;
            }

            .selectAction {
              flex-grow: 1;
            }
          }
        }

        .subrentalItemRow {
          border-top: 0px;
          display: none;
          @include breakpoint($tablet) {
            display: table-row;
          }

          .arrow {
            svg {
              width: 30px;
              height: 28px;
            }
          }

          .rTableCell {
            border-top: 0px;
          }
        }

        .rTableCell,
        td {
          &.barcode,
          &.amountInput {
            @include breakpoint($tablet) {
              padding-left: 17px;
            }
          }
        }

        .rTableHeading,
        thead,
        .rTableBody,
        tbody {
          .rTableRow,
          tr {
            .rTableHead,
            th,
            .rTableCell,
            td {
              &.action {
                & > svg {
                  width: auto;
                  margin: 0;
                }
              }
              &.quantityInput {
                width: 85px;
                padding: 5px 0;
                text-align: right;

                &.picklist {
                  display: none;

                  @include breakpoint($tablet) {
                    text-align: left;
                    width: 50px !important;
                    display: table-cell;
                  }
                }

                &.purchase {
                  width: 39px !important;
                }

                &.checkin-purchase {
                  width: 40px !important;
                }

                &.quantityCell {
                  font-size: 18px;
                }

                &.partiallyPicked {
                  color: #ff886d;
                }
              }

              &.description {
                width: 250px;
                min-width: 230px;

                .notes {
                  margin-bottom: 12px;
                  .label {
                    display: inline-block;
                  }

                  .itemNote {
                    font-size: 12px;
                    color: rgb(0, 0, 0);
                  }

                  p.first {
                    display: inline-block;
                  }
                }
              }

              &.departments {
                display: none;

                @include breakpoint($tablet) {
                  width: 90px;
                  display: table-cell;
                }
              }

              &.location {
                display: none;

                @include breakpoint($tablet) {
                  width: 116px;
                  display: table-cell;
                }
              }

              &.barcode {
                width: 35px;
                svg {
                  cursor: pointer;
                }
              }

              select {
                width: 100%;

                &.fullyAchieved {
                  background-color: #53c06e !important;
                  color: white;
                }

                &.partiallyAchieved {
                  background-color: #ff886d !important;
                  color: white;
                }
              }

              &.selectAction {
                min-width: 150px;
              }

              &.amountInput {
                min-width: 50px;
                max-width: 50px;

                @include breakpoint($tablet) {
                  width: 110px;
                  min-width: 110px;
                }

                .quickScan {
                  background-color: #e5e8e9;
                  width: 98%;
                  height: 30px;
                  font-weight: normal;
                  font-size: 12px;
                  text-transform: capitalize;
                  color: black;

                  svg {
                    width: 20px !important;
                    fill: #268ada;
                  }
                }

                input {
                  width: 42px;
                  text-align: center;
                  margin: 0;

                  @include breakpoint($tablet) {
                    width: 58px;
                  }
                }

                input[type='number']::-webkit-inner-spin-button,
                input[type='number']::-webkit-outer-spin-button {
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;
                  margin: 0;
                }
              }

              .noPaddingLeft {
                padding-left: 0px;
              }

              &.update {
                .btn {
                  padding-left: 22px;
                  padding-right: 22px;
                }
              }

              &.updateAll {
                .btn {
                  padding-left: 14px;
                  padding-right: 14px;
                }
              }

              &.update,
              .updateAll {
                width: 90px;
              }
            }
          }
        }
        .rTableBody .rTableRow,
        tbody tr {
          .rTableCell,
          td {
            padding-top: 12px;
            padding-bottom: 12px;

            p {
              margin: 0;
              font-size: 10px;
              line-height: 14px;
              color: $black;
              white-space: normal;
            }
            .info {
              position: absolute;
              top: 50%;
              right: 10px;
              transform: translate(0, -50%);
              margin: 0;

              svg {
                margin: 0;
              }
            }

            &.action a {
              margin: 0;

              svg {
                margin: 0;
              }
            }
            &.quantityInput {
              overflow: visible;

              span {
                display: inline-block;
                height: 30px;
                margin: 0 5px;
                line-height: 30px;
                text-align: right;
              }
              svg {
                fill: $orange;
              }

              & > div {
                margin-bottom: 5px;

                span {
                  margin: 0 5px 0 0;

                  &:last-of-type() {
                    position: relative;
                    padding: 0 5px 0;
                    color: $meddarkgrey;
                    text-align: left;

                    &:before {
                      content: ' ';
                      position: absolute;
                      left: 0;
                      top: 25%;
                      bottom: 25%;
                      width: 1px;
                      background-color: $meddarkgrey;
                    }
                  }
                }

                &:last-of-type {
                  margin-bottom: 0;
                }
              }
            }
          }
          .highlight {
            font-size: 9px;
            line-height: 13px;

            div {
              display: inline-block;
              margin-right: 10px;
            }
          }
        }

        &.rentalSection {
          margin-bottom: 20px;
        }

        &.thumbnail {
          .rTableBody,
          tbody {
            .rTableRow,
            tr {
              .rTableCell,
              td {
                height: 80px;
                vertical-align: middle;

                &.image {
                  padding: 0;

                  figure {
                    height: 70px;
                    width: 125px;
                  }
                }
              }

              .highlight {
                div {
                  display: block;
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  .details {
    @include breakpoint($tablet) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 10px;
      margin-top: 40px;
    }
  }

  section:not(.secondary),
  .mobilePickListToggles {
    .detailsToggle {
      float: right;
      margin-right: -10px;

      &.picklistToggles {
        display: none;

        @include breakpoint($tablet) {
          display: flex;
        }
      }

      label {
        font-size: 9px !important;
        line-height: 40px !important;
        color: $blue !important;

        & + div {
          margin-top: 11px;

          & > div {
            /* track */
            &:first-of-type() {
              height: 9px !important;
              width: 22px !important;
            }
            /* thumb */
            &:last-of-type() {
              top: 2px !important;
              height: 13px !important;
              width: 13px !important;
            }
          }
        }
      }
    }
  }

  .mobilePickListToggles {
    display: flex;
    flex-direction: row-reverse;
    padding-right: 10px;

    @include breakpoint($tablet) {
      display: none;
    }

    .detailsToggle,
    .picklistToggles {
      display: flex !important;
    }
  }

  .pickListStaff {
    .right {
      text-align: right;
    }
    .subHeading {
      display: grid;
      grid-template-columns: 3fr 2fr;
      font-weight: $bold;
      line-height: 40px;
      color: $blue;

      label {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .staffMember {
      margin-bottom: 10px;

      & > div:first-of-type() {
        display: grid;
        grid-template-columns: 3fr 2fr;
        margin-bottom: 10px;
        font-weight: $bold;

        label:last-of-type() {
          font-weight: $regular;
        }
      }
      .time {
        label {
          display: inline-block;
          width: 75px;
          font-size: 9px;
          line-height: 13px;
          color: $blue;
        }
        span:last-of-type() {
          position: absolute;
          right: 0;
        }
      }
    }
  }

  &.quotePickList,
  &.checkedInPickList {
    .items {
      .shortageTrigger {
        margin-right: 0;
      }
    }
  }
  &.reservationPickList,
  &.inUsePickList {
    .addons {
      grid-column: 1 / span 2;
    }
  }
  &.inUsePickList.hasReservedItems {
    section:not(.addons) {
      .grid.thumbnail {
        .rTableHeading .rTableRow .rTableHead.image,
        thead tr th.image {
          width: 75px;
        }
        .rTableBody .rTableRow .rTableCell.image,
        tbody tr td.image {
          width: 75px;

          figure {
            width: 70px;
          }
        }
      }
    }
    .addons {
      grid-column: 1 / span 3;
    }
  }
}

.checkInModal {
  .content {
    .body {
      .checkInItem {
        padding: 10px 0;
        overflow: auto;

        &:nth-of-type(n + 2) {
          border-top: 1px solid $meddarkgrey;
        }

        .focusHeader ul li {
          padding-left: 0;

          &.med {
            @include span(3 of 5);
          }
          &.sm {
            @include span(1 of 5);
          }
        }
        .details {
          svg {
            height: 17px;
            width: 18px;
            margin-right: 5px;
            fill: $blue;
            vertical-align: middle;
          }
          & > div {
            margin-bottom: 5px;
            line-height: 18px;

            &.name {
              @include span(3 of 5);
            }
            &.returned {
              @include span(1 of 5);
            }
            &.cost {
              @include span(1 of 5);
              text-align: right;
            }
            &.note {
              @include span(full);

              .highlight {
                display: inline-block;
                margin-left: 10px;
                color: $blue;
              }
            }
          }
        }
      }
      .btn {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

.checkInErrorModal {
  .content {
    .body {
      h3 {
        font-size: 18px !important;
        line-height: 20px !important;
        color: $darkblue !important;
        @include breakpoint($tablet) {
          width: calc(100% - 150px);
        }
      }
      .details,
      .fee {
        display: grid;
        align-items: center;
      }
      .details {
        grid-template-columns: repeat(5, 1fr);
        margin: 10px 0 20px;
        line-height: 30px;

        label {
          grid-row: 1;
          text-align: center;

          &:nth-of-type(3) {
            grid-column: 4;
          }
          &:nth-of-type(4) {
            grid-column: 5;
          }
        }
        span,
        input[type='number'] {
          grid-row: 2;
          font-size: 22px;
          text-align: center;
        }
        span:last-of-type {
          grid-column: 5;
        }
        input[type='number'] {
          width: 100%;
          margin: 0;

          &:last-of-type {
            grid-column: 4;
          }
        }
        textarea {
          grid-column: 1 / span 5;
          grid-row: 3;
          align-self: start;
          margin: 20px 0 0;
        }

        .alert {
          color: $orange;
        }
      }
      .fee {
        grid-template-columns: 2fr 1fr;
        grid-gap: 20px;
        margin: 20px 0;
        @include breakpoint($tablet) {
          grid-template-columns: 3fr 2fr 1fr;
        }

        span {
          grid-column: 1;
        }
        label {
          grid-column: 1;
          color: $blue;
          @include breakpoint($tablet) {
            grid-column: 2;
            justify-self: end;
          }

          & + input[type='text'] {
            grid-row: 2;
            @include breakpoint($tablet) {
              grid-row: 1;
            }
          }
        }
        input[type='text'] {
          grid-column: 2;
          margin: 0;
          @include breakpoint($tablet) {
            grid-column: 3;
            width: 100%;
          }
        }
      }
    }
  }
}

@media print {
  .pickList {
    .actions {
      display: none;
    }
    .details,
    .items {
      @include push(1 of 8);
      @include span(6 of 8);
      @include post(1 of 8);
    }
  }
}

.show-inventory-images-modal {
  .content {
    background: white !important;
    .drop.toggle {
      background: #e6e8e9;
    }
  }
}

.inventory-images-modal-header {
  padding: 15px 0px 10px !important;
  border-bottom: 1px solid #278ada !important;
  margin-bottom: 20px !important;
  .modal-header-checkbox {
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    line-height: 10px;
    span {
      color: #2889da;
      font-weight: bold;
    }
    .close-modal {
      display: flex;
      align-items: center;
      .close-button {
        font-size: 20px;
        margin-left: 15px;
        line-height: 10px;
      }
    }
  }
}
.preview-image {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 10px;
  position: relative;
  img {
    height: 300px;
    margin: 0 auto;
  }
  .remove-icon {
    top: 8px;
    right: 7px;
    position: absolute;
    cursor: pointer;
    fill: #466b87;
    width: 20px !important;
    height: 20px !important;
  }
}

.modal-inner-Content {
  height: 338px;
  .no-image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: inherit;
    img {
      width: 92px;
      height: auto;
    }
    span {
      margin-top: 10px;
    }
  }
  .images {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 15px;
    height: 338px;
    overflow-y: auto;
    align-content: baseline;
    img {
      width: 100%;
      margin-bottom: 15px;
      cursor: pointer;
    }
  }

  .image-overlay {
    background-color: rgb(0, 0, 0);
    width: 100%;
    height: 100%;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: 15px;
  }

  .image-overlay-container {
    position: relative;
  }

  .spinner-image-overlay {
    padding: 0;
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
  }
}

.btn {
  &.disabled-rental-picture {
    background-color: rgb(77, 107, 133) !important;
    color: white !important;
  }
}

.business-signatures {
  display: grid;
  grid-template-columns: 1fr 120px;
  grid-gap: 10px;
  .upload-wrapper {
    background-color: #fff;
    height: 90px;
    padding: 10px;
    cursor: pointer;
    .upload-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #b5cee3;
      height: 100%;
      text-align: center;
      img {
        width: 100px;
        height: auto;
      }
      span {
        font-size: 9px;
        line-height: 10px;
      }
    }
  }
}
.preview-image-actions {
  display: grid;
  grid-template-columns: 4fr 2fr 2fr 4fr 2fr;
  margin-top: 5px;
  margin-bottom: 25px;

  .delete-svg {
    fill: #ca3a3a;
    float: right;
    height: 20px !important;
  }
  .downlaod-svg {
    float: right;
    fill: #466b87;
    height: 20px !important;
  }
}

.color-box-selected-overlay {
  position: relative;
  .colorBox {
    width: 20px;
    height: 20px;
    margin-right: 5px;

    &:hover {
      cursor: pointer;
    }

    &.clear {
      margin-right: 0px;
    }
  }

  &.clear {
    &:hover {
      cursor: pointer;
    }
  }

  #cross {
    width: 28px;
    height: 4px;
    top: 28px;
    left: 18px;
    border-bottom: 1px solid #c1cdd7;
    -webkit-transform: translateY(-21px) translateX(-21px) rotate(45deg);
    position: absolute;
  }

  svg {
    width: 14px;
    position: absolute;
    z-index: 1;
    top: 4px;
    left: 3px;
    fill: white;
  }
}

.accordian {
  .header {
    .picklist-setting-actions {
      display: none !important;
    }

    &:hover {
      .picklist-setting-actions {
        display: flex !important;
      }
    }
  }
}

.pointer {
  &:hover {
    cursor: pointer;
  }

}

.noPaddingLeft {
  padding-left: 0px !important;
}
