.rentalWrapper {
  @media screen and (max-width: 768px) {
    h4 {
      font-size: 16px;
      font-weight: 700;
      line-height: 30px;
    }

    .rentalItems,
    .rentalSchedule,
    .rentalCustomers,
    .rentalDelivery {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  header {
    height: auto;

    @include breakpoint($tablet) {
      height: 200px;
    }

    &>div {
      display: grid;

      @media (max-width: 769px) {
        margin-top: 10px;
      }

      @include breakpoint($tablet) {
        grid-template: 90px 80px 30px / 3fr 1fr;
      }
    }

    .title {
      padding: 10px;
      margin: 0 -10px;
      background-color: $darkblue;
      color: $darkgrey;
      text-align: center;

      @media (max-width: 769px) {
        margin-top: 5px;
      }

      @include breakpoint($tablet) {
        grid-column: 1;
        grid-row: 1;
        place-self: center start;
        min-width: 0;
        width: 650px;
        padding: 0;
        margin: 0;
        font-size: 13px;
        line-height: 14px;
        text-align: left;
      }

      h1 {
        margin: 0;
        color: $white;

        @include breakpoint($tablet) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .progressHolder {
      @include breakpoint($tablet) {
        grid-column: 1;
        grid-row: 2;
        place-self: start;
      }
    }

    .tabWrapper {
      height: 40px;
      padding: 10px 10px 0;
      margin: 0 -10px 20px;
      background-color: $darkblue;
      white-space: nowrap;
      overflow-y: visible;
      overflow-x: scroll;

      .borderTop {
        margin-top: -5px;
        border-top: 5px solid #ff886d;
        height: 35px;
      }

      @include breakpoint($tablet) {
        grid-column: 1;
        grid-row: 3;
        place-self: end start;
        display: flex;
        height: 30px;
        width: 100%;
        padding: 0;
        margin: 0;
        overflow-x: visible;
      }

      a {
        position: relative;
        display: inline-block;
        height: 30px;
        width: 80px;
        margin-right: 1px;
        @include border-radius(3px 3px 0 0);
        line-height: 30px;
        font-weight: $light;
        background-color: $midblue;
        color: $darkgrey;
        text-align: center;
        white-space: nowrap;

        @include breakpoint($tablet) {
          flex: 1;
          width: auto;
          max-width: 120px;
          padding-left: 5px;
          padding-right: 5px;
        }

        svg.alertIcon {
          fill: $orange !important;
        }

        svg {
          display: none !important;
          height: 14px;
          width: 14px;
          margin-right: 5px;
          fill: $darkgrey;
          vertical-align: middle;

          @include breakpoint($tablet) {
            display: inline-block !important;
          }
        }

        .callout {
          position: absolute;
          top: -8px;
          right: -8px;
          padding: 4px 6px 3px;
          @include border-radius(8px);
          background-color: $blue;
          font-size: 10px;
          font-weight: $light;
          line-height: 10px;
          color: $white;
          z-index: 1;
        }

        &.active {
          background-color: $lightgrey;
          font-weight: $bold;
          color: $darkblue;

          svg {
            fill: $darkblue;
          }
        }

        &.noMobile {
          @include breakpoint($tablet) {
            display: inline-block !important;
          }
        }
      }
    }

    .rentalActions {
      color: $darkgrey;
      margin-bottom: 20px;

      .smartButton {
        margin-bottom: -10px;
      }

      .Dropdown-menu {
        color: $darkblue;

        .Dropdown-option {
          margin-top: 0px;

          &:hover {
            background-color: $blue;
            color: white;
          }

          &.is-selected {
            background-color: $darkblue;
            color: white;
          }
        }
      }

      @include breakpoint($tablet) {
        grid-column: 2;
        grid-row: 1 / span 2;
        place-self: end stretch;
        margin-bottom: 50px;
      }
    }

    .quoteExpiration {
      font-size: 10px;
      line-height: 14px;
      color: $darkgrey;
      text-align: center;

      @include breakpoint($tablet) {
        grid-column: 2;
        grid-row: 2;
        place-self: end stretch;
        margin-bottom: 30px;
      }

      &.expired {
        color: $red;
      }
    }

    .actions {
      grid-column: 1;
      grid-row: 1;
      place-self: end;
      display: block;

      @include breakpoint($tablet) {
        grid-column: 2;
        grid-row: 3;
        place-self: start end;
      }

      .btnLink {
        float: none;
        margin: 5px 0 5px 10px;
        font-weight: $regular;

        @include breakpoint($tablet) {
          display: inline-block !important;
        }

        svg {
          margin: 0;
        }
      }
    }
  }

  @media print {
    header {
      height: 100px;
      background-color: transparent;

      &>div {
        display: grid;
        grid-template: 1fr / 1fr;
        padding: 0;
      }

      .title {
        grid-column: 1;
        grid-row: 1;
        place-self: center;
        background-color: transparent;
        color: $darkblue;
        text-align: center;

        h1 {
          color: $darkblue;
        }
      }

      .progressHolder,
      .tabWrapper,
      .rentalActions,
      .quoteExpiration,
      .actions {
        display: none;
      }
    }
  }
}

.rental {
  display: grid;
  grid-gap: 10px;

  @media screen and (max-width: 768px) {
    &.isParentRental {
      grid-gap: 0;
    }
  }

  @include breakpoint($tablet) {
    grid-template: auto 1fr / repeat(4, 1fr);
  }

  .rentalDisplay,
  .rentalChangeRequests,
  .rentalSchedule,
  .rentalCustomers,
  .rentalDelivery,
  .rentalVenue,
  .extraPanel {
    min-width: 0;
    margin: 0;
  }

  .rentalDisplay,
  .rentalChangeRequests {
    @include breakpoint($tablet) {
      grid-column: span 4;
    }
  }

  .extraPanel {
    @media screen and (max-width: 768px) {
      .billing_status {
        padding-right: 15px;
      }

      .sectionEdit {
        margin-right: 15px;
      }

      h3 {
        font-size: 16px;
        line-height: 30px;
        padding-left: 15px;
      }

      h2 {
        font-size: 16px;
        font-weight: 400;
        padding-left: 15px;
      }

      .rentalSalesperson {
        p {
          padding-left: 15px;
        }
      }

      .noteItem {
        padding-left: 15px;
        padding-bottom: 15px;

        h6 {
          margin-bottom: 8px;
        }
      }

      .documents {
        a {
          &:not(.sectionEdit) {
            padding-left: 15px;
          }
        }
      }


      .noItems {
        padding-left: 15px;
      }
    }

    @include breakpoint($tablet) {
      grid-row: 1 / span 2;
      grid-column: 4;

      &>section {
        margin-bottom: 40px;
      }
    }
  }

  .rentalItems {
    min-width: 0;

    @include breakpoint($tablet) {
      grid-column: span 3;
      align-self: start;
    }
  }

  @include breakpoint($tablet) {

    &.hasChanges,
    &.isDisplay {
      @include breakpoint($tablet) {
        grid-template-rows: auto auto 1fr;
      }

      .extraPanel {
        grid-row: 2 / span 2;
      }

      .extraPanel.extraSection {
        grid-row: 1 / span 2;
      }
    }

    &.noVenue {
      grid-template-columns: 9fr 9fr 6fr;

      .extraPanel {
        grid-column: 3;
      }

      .rentalItems {
        grid-column: span 2;
      }
    }
  }
}

// progress bar for delayed job inventory update status
.inventoryUpdateStatusContainer {
  // grid-template-rows: auto;
  // grid-column: 1 / 4;
  // height: 30px;
  // background-color: #7d98b0;
  box-sizing: content-box;
  height: 30px;
  /* Can be anything */
  position: relative;
  margin-bottom: none !important;
  background: #eee;
  border-radius: 10px;
  font-family: sans-serif;
  color: #333;

  .inventoryUpdateStatusBar {
    display: block;
    height: 100%;
    background-color: #8ca9c3;
    background-image: linear-gradient(center bottom,
        rgb(43, 194, 83) 37%,
        rgb(236, 236, 236) 69%);
    box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
      inset 0 -2px 6px rgba(0, 0, 0, 0.4);
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(-45deg,
          rgba(255, 255, 255, 0.2) 25%,
          transparent 25%,
          transparent 50%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0.2) 75%,
          transparent 75%,
          transparent);
      z-index: 1;
      background-size: 50px 50px;
      animation: move 2s linear infinite;
      overflow: hidden;
    }
  }
}

.inventoryUpdateStatusBar {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.statusBarText {
  font-size: 11px;
  color: #ffffff;
  margin-top: 6px;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 50px 50px;
  }
}

header.deliveryAlert .rentalActions a.btn:not(.outline) {
  background-color: #ff886d;

  &:hover {
    background-color: #ff9f89;
  }
}

.rentalActions {
  div {
    margin-top: 10px;

    label {
      display: block;
      margin-bottom: 10px;
      text-align: center;

      @include breakpoint($tablet) {
        margin-bottom: 5px;
      }

      svg {
        height: 18px !important;
        width: 19px !important;
        margin: -3px 5px 0 0;
        color: $meddarkgrey;
        fill: $meddarkgrey;
        vertical-align: middle;
      }

      &.partial svg {
        fill: $purple;
      }

      &.active svg {
        color: $green;
        fill: $green;
      }

      &.alert svg {
        fill: $orange;
      }
    }
  }

  .dtStatusBoxIcon {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin: 0 0 4px 0;

    .statusTxt {
      width: 80px;
    }

    .textAlign {
      margin-right: 80px;
    }
  }

  .btn {
    width: 100%;
    padding: 0;
    margin: 0;

    &:nth-of-type(2) {
      margin-top: 5px;
    }
  }

  a:not(.btn) {
    display: block;
    margin-top: 5px;
    line-height: 30px;
    text-align: center;
    text-decoration: underline;

    &.change {
      color: $orange;
    }
  }
}

.rentalDisplay {
  background-color: $meddarkgrey !important;

  @include breakpoint($tablet) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 10px;
    padding: 15px 15px 15px 20px !important;
  }

  h2 {
    margin: 0;
    color: $darkblue;
  }

  &>p {
    @include breakpoint($tablet) {
      grid-column: span 2;
      margin: 0;
      width: span(3 of 4);
    }
  }

  .actions {
    @include breakpoint($tablet) {
      grid-column: 4;
      align-self: center;
    }

    .secondary {
      grid-column: span 3;
    }
  }

  &>div:not(.actions) {
    margin: 10px 0;

    @include breakpoint($tablet) {
      grid-column: span 2;
      margin: 0;
    }

    .strikeThrough {
      color: $darkgrey;
    }
  }
}

/* Appearance */
.rentalChangeRequests {
  h2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: $orange;

    @include breakpoint($tablet) {
      grid-template-columns: 1.5fr 2fr 2fr 2fr 1fr;
    }

    span {
      grid-column: span 2;

      @include breakpoint($tablet) {
        grid-column: span 1;
      }
    }

    .caption {
      margin: 0;
    }
  }

  &>div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px 0;
    border-bottom: 1px solid $meddarkgrey;

    @include breakpoint($tablet) {
      grid-template-columns: 1.5fr 2fr 2fr 2fr 1fr;
    }

    h4 {
      grid-column: span 2;
      color: #000000;

      @include breakpoint($tablet) {
        grid-column: span 1;
        margin: 0;
      }
    }

    &>* {
      min-width: 0;
    }

    &:last-of-type {
      border-bottom: none;
    }

    &.itemChanges {
      &>div>div {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid $meddarkgrey;

        @include breakpoint($tablet) {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &:last-of-type {
          padding-bottom: 0;
          margin-bottom: 0;
          border-bottom: none;
        }
      }

      .priceDiff {
        .oldPrice {
          text-decoration: line-through;
          color: #b5cee3;
          margin-right: 10px;
        }
      }
    }

    .subChangeRequestButtons,
    .subChangeRequestStatus {
      a {
        margin: 0 5px;
      }

      svg {
        width: 14px !important;
        height: 11px !important;
        fill: #c1cdd7;

        &.approved {
          fill: #54c06e !important;
        }

        &.denied {
          fill: #d3347f !important;
        }

        &.filledNotes {
          fill: #278ada !important;
        }
      }
    }
  }
}

.rentalChangeRequestsModal {
  .rentalChangeRequests {
    h2 {
      @include breakpoint($tablet) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }

    &>div {
      @include breakpoint($tablet) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
  }
}

.rentalSchedule,
.rentalCustomers,
.rentalDelivery,
.rentalVenue {
  padding-bottom: 20px !important;

  a:not(.btn) {
    display: block;
    color: $darkblue;
    text-overflow: ellipsis;
    overflow: hidden;

    &.link {
      color: $blue;
      text-decoration: underline;
    }
  }

  h4 {
    margin-bottom: 0;
  }

  strong {
    display: block;
    line-height: 20px;
  }
}

.rentalSchedule {
  div>div {
    margin-bottom: 10px;
  }

  span {
    display: block;
  }

  h4:nth-of-type(2) {
    margin-top: 10px;
  }

  .flip,
  .setByTime {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid $meddarkgrey;
  }
}

.rentalCustomers {
  .customers>div {
    margin-bottom: 20px;

    .caption {
      margin: 0;
      color: $blue;
    }

    svg {
      width: 12px;
      height: 15px;
      margin-right: 3px;
      fill: $blue;
      vertical-align: middle;
    }
  }

  .company {
    padding-bottom: 20px;
    margin-bottom: 20px;

    .caption {
      margin: 0;
      color: $blue;
    }
  }

  .borderBottom {
    border-bottom: 1px solid $meddarkgrey;
  }

  .borderTop {
    border-top: 1px solid $meddarkgrey;
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

.rentalDelivery {
  .venue {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $meddarkgrey;
  }

  .times {
    h4 {
      margin-top: 20px;
    }

    label {
      font-size: 10px;
      color: $blue;
    }

    strong {
      margin-top: 10px;
    }
  }

  .alert {
    color: $orange;
    font-weight: bold;
  }
}

.rentalItems {
  .btnIcon {
    padding: 0 40px;

    @include breakpoint($tablet) {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }

  .headerInput {
    width: 50%;
    margin-top: 5px;
  }

  .grid {
    .normalText {
      white-space: normal;
    }

    .rightGap {
      padding-right: 25px;
    }

    .caption {
      position: static !important;
      margin: 0;
    }

    td {
      white-space: break-spaces;

      &>svg {
        fill: $orange;
      }

      &.isFulfilled>svg {
        fill: $green;
      }
    }

    .details {
      background-color: $lightgrey;

      td {
        white-space: normal;

        &:first-of-type,
        &.image+td {
          padding-left: 5px;

          span {
            display: inline-block;
            margin-left: 5px;
            font-size: 10px;
            color: $blue;

            .btnChat {
              svg {
                height: 14px;
                width: 15px;
                vertical-align: middle;
                margin: 0 0 0 5px;
                fill: $blue;
              }
            }
          }
        }

        .btn {
          width: 100%;
          padding: 0;
          margin: 0 0 5px;

          @include breakpoint($tablet) {
            width: calc(#{span(1 of 2)} - 5px);
            margin: 0 5px 0 0;
          }

          svg {
            fill: $white;
          }

          &.outline {
            @include breakpoint($tablet) {
              width: calc(#{span(1 of 4)} - 5px);
            }
          }
        }

        &.actionSm {
          .btnLink {
            margin-right: 5px;
          }

          .connect svg {
            fill: $blue;
          }
        }

        &.multilocation {
          .btn {
            @include breakpoint($tablet) {
              width: calc(#{span(1 of 5)} - 5px);

              &:first-of-type {
                width: calc(#{span(2 of 5)} - 5px);
              }
            }
          }
        }

        &.grouped {
          .btn {
            @include breakpoint($tablet) {
              &:first-of-type {
                width: calc(#{span(2 of 5)} - 10px);
              }
            }
          }
        }

        &.bundle {
          .btn {
            @include breakpoint($tablet) {
              &:first-of-type {
                width: calc(#{span(2 of 5)} - 20px);
              }
            }
          }
        }
      }
    }
  }

  &>div {
    margin-bottom: 40px;

    &:first-of-type {
      margin-bottom: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .grid:nth-of-type(2),
    .dragGrid:first-of-type {
      tr:first-of-type td {
        border-top: 2px solid $blue;

        td {
          border-top: 1px solid $meddarkgrey;
        }
      }
    }

    th input[type='text'] {
      width: 100%;
    }

    .dragPlaceholder {
      position: relative;
      margin-top: 7px;

      &:before {
        content: ' ';
        position: absolute;
        top: -8px;
        left: -1px;
        right: -1px;
        height: 2px;
        background-color: $blue;
      }
    }

    .subtotal {
      border-top: 2px solid $darkblue;
      line-height: 30px;
      text-align: right;

      strong {
        margin-right: 5px;
      }
    }
  }

  .indent {
    border-top: none;

    td {
      border-top: 1px solid $meddarkgrey !important;
    }
  }

  .rentalStaff {
    .caption {
      margin: 0 5px;
      color: $blue;
    }

    .summary {
      @media screen and (max-width: 768px) {
        margin-bottom: 8px;
      }
    }

    .rentalStaffContainer {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .timeDetails {
      margin-top: 5px;
    }

    .timeEntry {
      margin-left: 5px;
    }

    .summary,
    .details {
      &>div {
        line-height: 35px;

        @media screen and (max-width: 768px) {
          line-height: 20px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .caption {
            flex: 1;
          }

          span {
            &:last-of-type {
              width: 80px;
              text-align: right;
            }
          }
        }

        .caption {
          display: inline-block;
          width: 60px;
          color: $blue;
        }

        .right {
          float: right;
        }
      }
    }

    .details {
      padding: 0 5px;
    }

    a {
      color: $darkblue;
    }
  }

  .actionSm {
    .pointerEvents {
      padding: 0 7px;

      .duplicateBtn,
      .splitBtn {
        display: block;
        margin: 7px 0;

        img {
          display: inline-block;
          position: relative;
          vertical-align: middle;
          margin-right: 3px;
        }
      }
    }
  }
}

.feedbacks {
  padding: 20px;
  background-color: $cobalt;
  color: $white;

  .feedback {
    div {
      margin-bottom: 20px;

      img,
      h2 {
        display: inline-block;
        vertical-align: middle;
      }

      img {
        height: 50px;
        width: 50px;
        margin-right: 10px;
      }

      h2 {
        margin: 0;
        color: $white;
      }
    }

    &>label {
      position: relative;
      display: block;
      font-weight: $bold;

      span {
        position: absolute;
        right: 0;
        font-size: 10px;
        font-weight: $regular;
      }
    }
  }
}

.billing {
  .billing_status {
    float: right;

    span {
      margin-left: 5px;
      text-transform: capitalize;
    }
  }

  h3 {
    margin: 0;
  }

  .charges,
  .payments,
  .earnings,
  .expenses {
    margin-bottom: $innerPadding;

    &>div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 11px 0 9px;
      border-top: 1px solid $meddarkgrey;

      @include breakpoint($tablet) {
        padding: 13px 0 11px;
      }

      .indent {
        padding-left: 20px;
      }

      .info {
        float: none;
        margin-left: 5px;
      }

      &:first-of-type():not(.total) {
        border-top: 1px solid transparent;
      }

      &>label {
        white-space: normal;
      }

      &>span {
        text-align: right;
      }

      .grid {
        grid-column: 1 / span 2;
      }
    }

    .subtotal,
    .total {
      .display-flex {
        display: flex;
      }

      padding: 10px 0 9px;
      border-top: 2px solid $darkblue;

      @include breakpoint($tablet) {
        padding: 12px 0 11px;
      }
    }

    .total {
      border-top: 2px solid $blue;
      color: $blue;
    }
  }

  .charges {
    @media screen and (max-width: 768px) {
      label {
        padding-left: 15px;
      }

      span {
        padding-right: 15px;
      }
    }

    .total {
      position: relative;
      padding-top: 14px;
      font-weight: $bold;

      @include breakpoint($tablet) {
        padding-top: 16px;
      }

      &:before {
        content: ' ';
        position: absolute;
        top: 2px;
        left: 0;
        right: 0;
        border-top: 2px solid $blue;
      }
    }
  }

  .payments {

    @media screen and (max-width: 768px) {
      label {
        padding-left: 15px;
      }

      span {
        padding-right: 15px;
      }
    }

    .grid {

      thead tr th {
        height: auto;
        color: $cobalt;

        &:last-of-type() {
          color: $blue;
        }
      }
    }
  }

  .earnings {
    &>div:first-of-type():not(.total) {
      border-top: 1px dashed $darkblue;
    }
  }

  .expenses {
    &>div:first-of-type():not(.total) {
      border-top: 1px solid $meddarkgrey;
    }
  }

  &>div {
    label.caption {
      margin: 0 5px;
      font-size: 12px;
      line-height: 15px;
      font-weight: $regular;
      color: $darkgrey;
      text-transform: capitalize;

      @include breakpoint($tablet) {
        font-size: 9px;
        line-height: 12px;
      }
    }
  }
}

.rentalSalesperson {
  p {
    margin-top: 15px;
    margin-bottom: 6px;
  }

  h6 {
    color: $blue;
  }
}

.expiration {
  .alert {
    color: $orange;
    line-height: 24px;

    svg {
      height: 15px;
      width: 16px;
      margin-right: 5px;
      fill: $orange;
      vertical-align: middle;
    }
  }

  .react-datepicker-component {
    width: span(1 of 2);
    margin-left: 23px;

    @include breakpoint($tablet) {
      width: span(1 of 3);
    }
  }

  .actions {
    margin-top: 20px;
  }
}

.drawerFooter.footerActions {
  position: fixed;
}

.notes,
.history,
.documents {
  &>div:not(.form) {
    @include container;
    padding-top: 10px;
    border-top: 1px solid $meddarkgrey;

    .drawerFooter.footerActions {
      position: fixed;
      display: flex;
      width: 100%;
      margin-bottom: 0;
    }

    strong {
      display: block;
    }

    h6 {
      color: $blue;

      span {
        display: inline-block;
        width: span(3 of 4);

        &.adjusted-by {
          font-weight: bold;
          font-size: 12px;
          color: #000000;
          margin-bottom: 10px;
        }

        &:last-of-type {
          width: span(1 of 4);
        }

        &.w65 {
          width: 65%;
        }

        &.w65 {
          width: 65%;
        }
      }
    }

    a:not(.btnLink) {
      display: block;
      width: span(9 of 10);
      margin-bottom: 10px;
    }

    a {
      text-decoration: underline;
    }

    .check {
      clear: both;
      margin: 0 0 10px !important;

      label {
        font-size: 10px;
        color: $blue;
      }
    }

    .btnLink {
      margin-top: 0;
    }

    &:first-of-type {
      padding-top: 0;
      border-top: none;
    }
  }

  &>p {
    padding-top: 10px;
  }

  .additionalNotes {
    div {
      @include container;
      padding-top: 10px;
      border-bottom: 1px solid $meddarkgrey;

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        margin-bottom: 10px;
      }
    }
  }
}

.documents {
  div:not(.form) {
    padding-bottom: 0 !important;

    .check {
      margin: 0 !important;
    }

    .drawerFooter.footerActions {
      position: fixed;

      .btn {
        text-decoration: none;
      }
    }

    h6 {
      span {
        width: 15% !important;

        &.name {
          width: 60% !important;
        }
      }

      margin-bottom: 5px;
    }
  }

  .highlight {
    color: $blue;
  }
}

.notes {

  ul,
  ol {
    margin-left: 15px;
  }

  ul {
    list-style-type: initial;
  }

  ol {
    list-style-type: decimal;
  }
}

.notesList {

  ul,
  ol {
    margin-left: 15px;
  }

  ul {
    list-style-type: initial;
  }

  ol {
    list-style-type: decimal;
  }

  h6 {
    color: $blue;

    span {
      display: inline-block;
      width: span(1 of 4);

      &:last-of-type {
        width: span(3 of 4);
      }
    }
  }
}

.noteDrawer {
  margin: 0 span(1 of 20) span(3 of 20);
  overflow-y: auto;

  .check {
    label {
      color: $lightgrey;
    }
  }

  .radioList {
    margin-left: 20px !important;

    .radio label {
      color: white !important;
    }
  }
}

.noteDrawerWrapper {
  overflow: hidden;
  height: calc(100% - 40px);
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 5% 5%;
}

.subChangeRequestNoteFields {
  width: 90%;
  margin-left: auto;
  margin-right: auto;

  h2 {
    color: $blue;
    margin: 0 0 10px 0;
  }

  textarea {
    width: 100%;
  }
}

.duplicateOverlay {
  background-color: rgba(0, 0, 0, 0) !important;
}

.rentalRowName {
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

.rentalRow__rentalToken {
  color: $bluegrey;
  font-size: 12px;
  font-weight: 300;
}

.rentalRow__eventNameContainer {
  display: flex !important;
  gap: 2px;
  color: $darkgrey;
  align-items: center;
  line-height: 20px;

  &:hover {
    &>.rentalRow__eventIcon {
      fill: $blue !important;
      transition: fill 0.2s;
    }

    &>.rentalRow__eventName {
      color: $blue;
      text-decoration: underline;
      transition: color 0.2s;
    }
  }
}

.rentalRow__eventIcon {
  margin-right: 0px !important;
  fill: $darkgrey;
}

.rentalRow__eventName {
  vertical-align: middle;
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 300;
  color: $darkgrey;
}

.rentalToken {
  font-weight: 400;
  text-transform: uppercase;
  color: #9cacb9;
  min-width: 73px;
  display: inline-block !important;
}

.loadMoreLink {
  text-align: center;
  margin: 10px 0;
}

.recurringPaymentPendingModal {
  color: #278ada !important;
  font-size: 16px !important;
  padding: 10px 0 !important;

  svg {
    fill: $orange;
    height: 16px;
    width: 21px;
  }
}

.isTempItem {
  fill: $orange;
  width: 17px !important;
  height: 17px !important;
}

.isSubRental {
  fill: $green;
  width: 17px !important;
  height: 17px !important;
}

.disabledActionButtons {
  opacity: 0.5;
  pointer-events: none;
}

.defaultHeaderDescription {
  color: #7194ac;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 14px;
  padding-left: 7px;
}

.defaultHeader {
  display: inline-flex;
  align-items: center;
}

.servicesClock {
  fill: $cobalt;
  width: 27px !important;
  height: 20px !important;
}

.blue-label {
  color: $blue;
}

.serial-numbers-rental-item-display {
  border-top: none !important;
}

.serialized-item-barcode {
  fill: black !important;
}

.serialNumbersTitle {
  color: $blue;
}

.serialNumbers {
  font-size: 10px;
}