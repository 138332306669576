.report {
  .headerNav {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    margin-bottom: 25px;

    h1 {
      display: inline-block;
      margin-bottom: 0;
    }

    min-height: 40px;

    .btnLink {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: $bold;
      color: $darkgrey;
      cursor: pointer;
      margin-right: 0;

      svg {
        height: 24px !important;
        width: 25px !important;
        fill: $cobalt;
        vertical-align: middle;
        margin-left: 0;
      }
    }

    .btnLink.excel {
      svg {
        fill: #00744c;
      }
      &:hover {
        svg {
          fill: $green;
        }
      }
    }

    .btnLink.help {
      font-weight: 400;
      svg {
        fill: $darkgrey;
        height: 26px !important;
        width: 26px !important;
        color: white;
        &:hover {
          fill: $darkgreyHover;
        }
      }
    }

    .navSubSection {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;

      .dropdownSection {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;

        svg {
          height: 15px !important;
          width: 15px !important;
        }
      }

      @include breakpoint($tablet) {
        min-height: 40px;
        padding-left: 25px;
        // margin-left: 25px;
        border-left: 1px solid $meddarkgrey;
      }
    }

    .flex-grow {
      flex-grow: 1;
    }

    .viewModeSelect {
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      margin: 0;
    }

    .iconGroup {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }
  }

  h2 {
    span {
      display: inline-block;
      margin-left: 5px;
    }
  }
  p {
    margin-top: 10px;
  }
  .reportNav {
    display: grid;
    grid-template-columns: max-content;
    margin-bottom: 0px;
    .headerNav {
      @include breakpoint($tablet) {
        margin-top: 0px;
      }

      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  .navigateBack {
    height: 30px;
    margin: 0 0 0;

    a {
      display: inline-block;
      font-size: 10px;
      font-weight: $bold;
      color: $darkgrey;
      text-transform: uppercase;

      svg {
        margin: 2px 10px -2px 0;
        fill: $blue;
      }
    }
  }
  .primary {
    h1,
    .highlight {
      color: $blue !important;
    }
  }
  .secondary {
    h1,
    .highlight,
    &.highlight {
      color: $orange !important;
    }
  }
  .tertiary {
    h1,
    .highlight,
    &.highlight {
      color: $purple !important;
    }
  }
  .details {
    clear: both;
    margin-bottom: $vertical;

    .summary {
      float: right;
      font-size: 18px;
      line-height: 40px;
      color: $darkgrey;

      & > span {
        margin-left: 10px;
        display: inline-block;
      }
    }
    h1 {
      padding: 0;
      margin: 0;
    }

    section {
      clear: both;
    }
    .grid {
      .detailCell {
        div {
          padding-right: 5px;
          overflow: hidden;
          text-overflow: ellipsis;

          &:nth-of-type(n + 2) {
            padding-top: 10px;
          }
        }
        label {
          display: inline-block;
          width: 80px;
          @include breakpoint($tablet) {
            width: 65px;
          }

          &.caption {
            margin: 0;
            color: $blue;
          }
        }
      }
      .subGrid {
      }
    }
  }

  .mb-20 {
  }

  .multilocation-section {
    padding-top: 24px;
    padding-bottom: 29px;

    .sub-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .multilocation-export {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #ced8e3;
    padding: 5px 0;

    .btnIcon {
      background-color: #e0e5e9;
      color: #0e2c44;
      text-transform: none;
      padding: 0 10px;

      svg {
        height: 15px !important;
        width: 15px !important;
      }

      &:hover,
      &.active {
        background-color: $blue;
        color: $white;

        svg {
          fill: $white;
        }
      }
    }
  }

  .date-select {
    margin: 19px 0px 50px;

    label {
      display: inline-block;
      height: 14px;
      width: 38px;
      color: #7194ac;
      font-size: 10px;
      letter-spacing: 0;
      line-height: 14px;
    }

    .react-datepicker-component {
      margin-bottom: 5px;
    }
  }

  .generalLedgerReport {
    display: grid;
    grid-template-columns: 1fr 3fr;

    .table {
      grid-column: 2;
      padding-top: 16px;
      padding-bottom: 24px;

      .linkBack {
        padding: 0;
        height: 0px;
      }

      .lineItemTable {
        margin: 36px 0;
        td {
          border: 0;
          padding-right: 8px;
        }
      }

      .grandTotalRow {
        td {
          border-top: 2px solid $midblue;
        }
      }

      td {
        height: 30px;
      }
    }

    .filter {
      background-color: $lightgrey;
      padding: 10px 25px 10px 0;

      h2 {
        color: $darkblue;
        line-height: 27px;
        font-weight: 500;
      }

      .form {
        label {
          display: block;
          line-height: 14px;
          color: #278ada;
          font-size: 10px;
          margin-bottom: 6px;
        }

        .react-datepicker-component {
          width: 100%;
          margin-bottom: 16px;

          input {
            display: block;
          }
        }

        select {
          background-color: white;
          margin-top: 0;
          display: block;
          width: 100%;
          margin-bottom: 16px;
        }

        .mb-30 {
          margin-top: 30px;
        }
      }
    }
  }
}

.chartWrapper {
  position: relative;
  height: 850px;
  width: 100%;
  margin-top: 20px;
  overflow-x: auto;
  overflow-y: hidden;

  .xAxis {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .yAxis {
    position: absolute;
    top: 45%;
    transform: rotate(-90deg);
    transform-origin: top left;

    .note {
      font-size: 18px;
    }
  }
}

.itemEditText {
  width: 70%;
  padding: 5px;
  color: $darkgrey;
}

.radialChart {
  & > svg {
    width: 100% !important;
  }
  .rv-radial-chart__series--pie-label-primary {
    font-size: 16px !important;
  }
  .rv-radial-chart__series--pie-label-secondary {
    font-size: 12px !important;
    fill: $darkblue;
  }
  .rv-radial-chart__series--pie-label-inner-wrapper {
    &:first-of-type() {
      .rv-radial-chart__series--pie-label-primary {
        fill: $blue;
      }
    }
    &:last-of-type() {
      .rv-radial-chart__series--pie-label-primary {
        fill: $orange;
      }
    }
  }
}

.barChart,
.areaChart,
.lineChart {
  padding-left: 70px;

  & > svg {
    height: 100% !important;
    overflow: visible;
  }
  .rv-xy-plot__axis {
    & > line {
      stroke-width: 1px;
      stroke: #979797;
    }
  }
  .rv-xy-plot__axis--vertical {
    .rv-xy-plot__axis__ticks {
      line {
        stroke: transparent;
      }
      text {
        fill: $darkblue;
      }
    }
  }
  .rv-xy-plot__axis--horizontal {
    .rv-xy-plot__axis__ticks {
      line {
        stroke: transparent;
      }
      text {
        transform: translate(2px, 10px) rotate(-90deg) !important;
        fill: $darkblue;
      }
    }
  }
  .rv-discrete-color-legend {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: left;

    .rv-discrete-color-legend-item {
      color: $darkblue;
      text-align: left;

      .rv-discrete-color-legend-item__color {
        float: left;
        height: 20px;
        width: 30px;
        margin-right: 8px;
      }
      .rv-discrete-color-legend-item__title {
        display: inline-block !important;
        line-height: 18px;
      }
    }
  }

  &.hasLegend {
    padding-top: 40px;
  }
}

.pieReport {
  .options {
    @include span(full);
    @include breakpoint($tablet) {
      width: 245px;
    }

    .filter {
      margin-bottom: 0;
      border-bottom: 1px solid $darkgrey;
    }
    .total {
      line-height: 40px;

      label {
        font-size: 16px;
        color: $darkgrey;
      }
      .highlight {
        float: right;
        font-size: 16px;
        color: $blue;
      }
    }
  }
  .radialChart {
    float: left;
    width: 100% !important;
    margin: 20px 0;
    @include breakpoint($tablet) {
      width: span(11 of 17) !important;
      margin: 0;
    }

    & > svg {
      & > g {
        @include breakpoint($tablet) {
          transform: translate(230px, 200px) !important;
        }
      }
    }
  }
}

.exportReport {
  @include breakpoint($tablet) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;

    h1 {
      grid-column: span 2;
    }
  }

  .info {
    left: 4px;
    position: relative;
  }

  .actions,
  .filter,
  .items {
    @include breakpoint($tablet) {
      grid-column: span 2;
    }
  }
  .actions {
    grid-template-columns: repeat(5, 1fr);
    margin-bottom: 10px;
    text-align: center;
  }
  section {
    align-self: start;

    .right {
      float: right;
    }
    ul {
      li {
        line-height: 39px;
        border-top: 1px solid $meddarkgrey;
      }
    }
    .accordian {
      .content {
        padding: 0 0 20px 10px;
        @include breakpoint($tablet) {
          padding: 0 20px 20px;
        }

        .dateToggle {
          display: grid;
          grid-template-columns: 1fr 2fr;

          .dateSelect {
            width: 100%;
          }
        }
        select {
          grid-column: span 2;
          width: 100%;
        }
        .radioList {
          margin: 0;
        }
        .dateSelect {
          align-self: center;
          display: grid;
          grid-template-columns: 40px 1fr;
          grid-gap: 5px;
          align-items: center;

          label {
            font-size: 10px;
            line-height: 14px;
            color: $darkgrey;
          }
          .react-datepicker-component {
            width: 140px;
            margin-right: 5px;
          }
        }
        .check {
          grid-column: span 2;
          display: block;
          line-height: 39px;
          border-top: 1px solid $meddarkgrey;

          &.childCheck {
            padding-left: 20px;
          }
        }

        .rentalDatesFilter {
          .dateSection {
            .dateSelect {
              margin: 5px 0 15px 30px;
            }
          }
        }
      }

      &.otherPayment {
        border-top: none;
      }
    }
  }
}

.revenueReport {
  .grid {
    .nameCell {
      width: 70%;
    }

    .transactionsTable {
      padding: 10px 10px 30px 10px;
    }

    .iconContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      .expandIcon {
        fill: #8fa9be;
      }

      .expandIconActive {
        fill: #278ada;
      }
    }
  }

  .viewMode {
    margin-right: 0;
  }
}

.expandableTableRow {
  .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    .expandIcon {
      fill: #8fa9be;
    }

    .expandIconActive {
      fill: #278ada;
    }
  }
}

.commissionReport {
  .viewMode {
    margin-right: 0;
  }
}

.totalOrderVolumeReport {
  .viewMode {
    .btnIcon {
      margin: 0;
    }
  }
}

.grid {
  .nameCell {
    width: 70%;
  }

  .detailsTable {
    padding: 10px 10px 30px 10px;
    width: 100%;
  }

  .bold {
    font-weight: bold;
  }

  .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    .expandIcon {
      fill: #8fa9be;
    }

    .expandIconActive {
      fill: #278ada;
    }
  }
}

.inventoryReport {
  .grid .rTableHeading .rTableRow .rTableHead.sm,
  .grid .rTableBody .rTableCell.sm {
    width: 70px;
  }
}

.tapgoodsPaymentsReport {
  .overview {
    @include container;

    & > div {
      @include breakpoint($tablet) {
        @include span(1 of 2);
      }

      label {
        display: inline-block;
        width: span(2 of 3);
        font-weight: $bold;

        span {
          font-weight: $regular;
        }
      }
    }
  }
  .viewToggle {
    position: relative;
    text-align: center;
    margin-bottom: 20px;

    & > div {
      display: inline-block !important;
      width: auto !important;
      margin-right: 8px;
      vertical-align: middle;
    }
    &:before,
    &:after {
      content: ' ';
      position: absolute;
      top: 50%;
      height: 1px;
      width: span(1 of 3);
      background-color: $meddarkgrey;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
}
.billingReport {
  .grid {
    .rTableRow {
      .pickUp svg {
        fill: #8fa9be;
      }
      .Full {
        color: $green;
      }
      .Partial {
        color: $orange;
      }
      .None {
        color: $red;
      }
    }
  }
}

.reportNav {
  margin-bottom: 30px;
  display: flex;

  h1 {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  .reportlinks {
    display: flex;

    a {
      margin-left: 12px;
      display: flex;
      align-items: center;
      color: $darkblue;

      &.selected {
        color: $blue;
        font-weight: $bold;
      }
    }
  }
}

.serializedItemSelect {
  width: 95%;
}
